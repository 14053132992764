

import axios from 'axios';

import serveradresse_settings from '@/settings/serveradresse_settings.js';
const url = serveradresse_settings.config_server_adresse.url;


export default {
  
  async zeigegruppenmodule(credentials) {
    return axios
      .post(url + 'zeigegruppenmodule_per_id/', credentials)
      .then(response => response.data);
  },


};