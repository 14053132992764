// src/services/AuthService.js

import axios from 'axios';

import serveradresse_settings from '@/settings/serveradresse_settings.js';
const url = serveradresse_settings.config_server_adresse.url;

// const url = 'http://localhost:3100/api/';
//const url = 'https://api.technik-seenotretter.de/api/';

export default {
  login(credentials) {
    return axios
      .post(url + 'login/', credentials)
      .then(response => response.data);
  },
  signUp(credentials) {
    return axios
      .post(url + 'sign-up/', credentials)
      .then(response => response.data);
  },

  
  hole_user_id(credentials) {
    return axios
      .post(url + 'hole_user_id/', credentials)
      .then(response => response.data);
  },
  

  getSecretContent() {
    return axios.get(url + 'secret-route/').then(response => response.data);
  }
};