
<template>

<div class="text-center">

    <v-btn type="button" style="position:relative; float:left;   margin:20px 10px 10px 11px ;  padding:10px 5px 10px 5px;"
    depressed
    elevation="2"
    pa-2
    ma-2
    outlined
    small
    @click="fotos_anzeigen">
    <span v-if="fotolisteanzeigen">Schliessen</span>
    <span v-else>anzeigen ({{ anzahl_dateien }})</span> 
    </v-btn>

<br><br><br><br>
        <ul id="ul_fotoliste" v-if="fotolisteanzeigen"  style="margin-top:20px; position:relative; display: grid;grid-template-columns:repeat(3,1fr);">
        <li v-for=" item in fotoliste" :key="item" style="display:inline-block;" >
              <a v-bind:href="`${url_foto_anzeigen}/Gruppenmodule/${item.id_gmv}/Aufgabe-SAR-ID-${item.id_sar}/Aufgabe-ID-${item.id_aufgabe}/${item.dateiname}`" target="blank"><img class="css_foto_fotogalerie" v-bind:src="`${url_foto_anzeigen}/Gruppenmodule/${item.id_gmv}/Aufgabe-SAR-ID-${item.id_sar}/Aufgabe-ID-${item.id_aufgabe}/${item.dateiname}`"/></a>    
        </li>
        </ul>

</div>



</template>


<script>

import FotoanzeigenService from '@/services/FotoanzeigenService.js';
import serveradresse_settings from '@/settings/serveradresse_settings.js';

export default ({

data() {
        return {
            images: null,
            imgSrc: '',
            user_id :  localStorage.getItem("user_id"),
            fotoliste: '',
            fotolisteanzeigen: false,
            url_foto_anzeigen: serveradresse_settings.config_server_adresse.url_foto_anzeigen
        }
    },

 methods: {

          async fotos_anzeigen() {
            
                try {
                  const credentials = {
                        id_sar: this.id_sar,
                        id_aufgabe: this.id_aufgabe,
                    };
      
                  const response = await FotoanzeigenService.zeige_fotos_in_aufgaben(credentials);
                  this.fotoliste = response.recordset;
                  
                  this.fotolisteanzeigen = !this.fotolisteanzeigen;
                  
                }
                catch (error) {
                    this.msg = "" //error.response.data.schiffsliste;
                }
        },
    },

    props: {
          id_aufgabe: { type: Number, required: true },
          id_sar: { type: Number, required: true },
          anzahl_dateien: { type: Number, required: false },
    },

    components: {
    }

})
</script>


<style>
        .css_foto_fotogalerie {
            width:50%; min-width:100px; height:auto;
            margin:5px;
        }

        .schatten{
        opacity:0.7;
            background: #F8F8F8; 
            border: solid #BDBDBD 1px; 
            box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
            -webkit-box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
            -moz-box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.2)  ; 
        }

        .css_datei_upload{
        width:60%;
        }

        .calenderfeld{
            width: 177px;
            font-size: 0px;
            text-decoration: none;
        }

        .css_datumsanzeige {
        position:relative;
        font-size: 15px;
        top:50px;
        left:35px;
        }
        .select {
            width: 60px;
            max-height: 60px;
            font-size: 11px;
            }

        .right {
        float:right;
        margin-right:10%;
        }
</style>