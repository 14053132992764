// src/views/SignUp.vue

<template>



<div class="pa-6">

          <h2 class="primary--text">Registrieren</h2>

          <!-- ICONS    https://materialdesignicons.com/icon/view-dashboard -->

          <v-layout row wrap >

            <v-flex xs10 md7 lg7 class="ma-6">
              <v-text-field 
                label="Benutzname"
                v-model="username"
               
                hide-details="auto"
                prepend-inner-icon="mdi-account"
              >
              </v-text-field>
            </v-flex>


          <v-flex xs10 md7 lg7 class="ma-6">
              <v-text-field
                label="Passwort"
                v-model="password"
              
                hide-details="auto"
                prepend-inner-icon="mdi-lock"
              ></v-text-field>
          </v-flex>

          <v-flex xs10 md7 lg7 class="ma-6">
              <v-text-field
                label="Passwort wiederholen"
                v-model="password_repeat"
               
                hide-details="auto"
                prepend-inner-icon="mdi-lock"
              ></v-text-field>
          </v-flex>


          <v-flex xs10 md7 lg7 class="ma-6">
              <v-btn class="ma-8 right"
                depressed
                color="secondary"
                @click="signUp"
              >
                Registrieren
              </v-btn>
          </v-flex>

          </v-layout>
            
              <p v-if="msg">msg {{ msg }} <br><span>angemeldeter_user: {{angemeldeter_user}} </span></p>
  </div>






</template>
<script>
import AuthService from '@/services/AuthService.js';

export default {
  data() {
    return {
      username: '',
      password: '',
      password_repeat: '',
      msg: ''
    };
  },
  methods: {
    async signUp() {
      try {
        const credentials = {
          username: this.username,
          password: this.password,
          password_repeat: this.password_repeat
        };
        const response = await AuthService.signUp(credentials);
        this.msg = response.msg;
      } catch (error) {
        this.msg = error.response.data.msg;
      }
    }
  }
};
</script>