// src/router.js (Vue CLI 1.x & 2.x) oder src/router/index.js (Vue CLI 3.x oder neuer)

//  https://webdeasy.de/login-system-mit-node-vue-restapi-jwt-part-1-2/  (node Backend / REST-API)
// https://webdeasy.de/login-system-mit-nodejs-vue-js-vuex-part-2-2/  (vue client))

import Vue from 'vue';
import Router from "vue-router";
import Home from "./views/Home.vue";
import SignUp from "./views/SignUp.vue";
import Login from "./views/Login.vue";
import Schiffsliste from "./views/Schiffsliste.vue";
import Gruppenmodulliste from "./views/Gruppenmodulliste.vue";
import Einzelmodulliste from "./views/Einzelmodulliste.vue";

// import Aufgabenliste from "./views/Aufgabenliste.vue";

import Aufgabenliste_neu from "./views/Aufgabenliste_neu.vue";
import Checkpunkte from "./views/Checkpunkte.vue";
import Stationsverbraeuche from "./views/Stationsverbraeuche.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  
  base: process.env.BASE_URL,
  props: ['id'],
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/home/",
      name: "home1",
      component: Home,
      props: true 
    }, 
    {
      path: "/sign-up",
      name: "sign-up",
      component: SignUp
    } ,
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/schiffsliste",
      name: "schiffsliste",
      component: Schiffsliste
    },
    {
      path: "/gruppenmodulliste",
      name: "gruppenmodulliste",
      component: Gruppenmodulliste
    },
    {
      path: "/einzelmodulliste",
      name: "einzelmodulliste",
      component: Einzelmodulliste
    },
    // {
    //   path: "/aufgabenliste",
    //   name: "aufgabenliste",
    //   component: Aufgabenliste
    // },
    {
      path: "/aufgabenliste_neu",
      name: "aufgabenliste_neu",
      component: Aufgabenliste_neu
    },
    {
      path: "/checkpunkte",
      name: "checkpunkte",
      component: Checkpunkte
    },
    {
      path: "/stationsverbraeuche",
      name: "stationsverbraeuche",
      component: Stationsverbraeuche
    }
  ]
});