// src/services/AuthService.js

import axios from 'axios';


import serveradresse_settings from '@/settings/serveradresse_settings.js';
const url = serveradresse_settings.config_server_adresse.url;


export default {


  async neue_aufgabe_einfuegen(credentials) {
    return axios
      .post(url + 'neue_aufgabe_einfuegen/', credentials)
      .then(response => response.data);
  },

  async aufgabe_loeschen(credentials) {
    return axios
      .post(url + 'aufgabe_loeschen/', credentials)
      .then(response => response.data);
  },

 async zeigeaufgabe_per_id(id_sar) {
    return axios
      .post(url + 'zeigeaufgabe_per_id/', id_sar)
      .then(response => response.data);
  },
 
  async zeigeaufgaben(credentials) {
    return axios
      .post(url + 'zeigeaufgaben/', credentials)
      .then(response => response.data);
  },


  async zeigeaufgaben_mit_auswahl(credentials) {
    return axios
      .post(url + 'zeigeaufgaben_mit_auswahl/', credentials)
      .then(response => response.data);
  },

  async zeigeaufgabe_per_id_zuordnung_final_gmv_sar(credentials) {
    return axios
      .post(url + 'zeigeaufgabe_per_id_zuordnung_final_gmv_sar/', credentials)
      .then(response => response.data);
  },

  async zeigeaufgabe_per_id_aufgabe(credentials) {
    return axios
      .post(url + 'zeigeaufgabe_per_id_aufgabe/', credentials)
      .then(response => response.data);
  },
  


  async update_aufgabe(credentials) {
    return axios
      .post(url + 'update_aufgabe/', credentials)
      .then(response => response.data);
  },

  async update_text_feld(credentials) {
    return axios
      .post(url + 'update_text_feld/', credentials)
      .then(response => response.data);
  },

  async update_checkbox_feld(credentials) {
    return axios
      .post(url + 'update_checkbox_feld/', credentials)
      .then(response => response.data);
  },

};