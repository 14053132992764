

import axios from 'axios';

import serveradresse_settings from '@/settings/serveradresse_settings.js';
const url = serveradresse_settings.config_server_adresse.url;


export default {
  
  async zeigestationsverbraeuche(credentials) {
    return axios
      .post(url + 'stationsverbraeuche_anzeigen/', credentials)
      .then(response => response.data);
  },


  async neuen_stationsverbrauch_einfuegen(credentials) {
    return axios
      .post(url + 'neuen_verbrauch_einfuegen/', credentials)
      .then(response => response.data);
  },


  async zeigestationen(credentials) {
    return axios
      .post(url + 'zeigestationen/', credentials)
      .then(response => response.data);
  },
  

};