// src/services/AuthService.js

import axios from 'axios';


import serveradresse_settings from '@/settings/serveradresse_settings.js';
const url = serveradresse_settings.config_server_adresse.url;


// const url = 'http://localhost:3100/api/';
//const url = 'https://api.technik-seenotretter.de/api/';


export default {


  async zeige_fotos_in_aufgaben(credentials) {
    return axios
      .post(url + 'zeige_fotos_in_aufgaben/', credentials)
      .then(response => response.data);
  },

 



  

};