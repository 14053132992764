

import axios from 'axios';

import serveradresse_settings from '@/settings/serveradresse_settings.js';
const url = serveradresse_settings.config_server_adresse.url;

// const url = 'http://localhost:3100/api/';
//const url = 'https://api.technik-seenotretter.de/api/';


export default {
  
  async zeigeeinzelmodule(credentials) {
    return axios
      .post(url + 'zeigeeinzelmodule_per_id/', credentials)
      .then(response => response.data);
  },

//  async zeigeaufgabe_per_id(id_sar) {
//     return axios
//       .post(url + 'zeigeaufgabe_per_id/', id_sar)
//       .then(response => response.data);
//   },
//   
//   async update_aufgabe(credentials) {
//     return axios
//       .post(url + 'update_aufgabe/', credentials)
//       .then(response => response.data);
//   },



};