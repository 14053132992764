
import { openDB } from 'idb';


// *************   https://hackernoon.com/use-indexeddb-with-idb-a-1kb-library-that-makes-it-easy-8p1f3yqq




export default {

  //*****************  Datenbank anlegen und Store  */
        async demo1() {
              openDB('db1', 1, {
                upgrade(db) {
                  db.createObjectStore('store1');
                  db.createObjectStore('store7');
                },
              });

              //****************  bei db2/store4 wird der Key automatisch eingefügt,- vermeidet Fehler */
          openDB("db2", 1, {
            upgrade(db) {
              db.createObjectStore('store3', { keyPath: 'id' });
              db.createObjectStore('store4', { autoIncrement: true });
            }
          })
        },

//         //***** EINFÜGEN */

//         //*****  db1.add('store', 'key', 'value') */
//         //*****  wenn gleicher Key mehrfach eingefügt wird, dann gibt es einen Fehler */
//         async demo2() {
//           const db1 = await openDB('db1', 1);
//           db1.add('store1', '11hello world123', '11message123')
//           .then(result => {
//             console.log('success!', result);
//           })
//           .catch(err => {
//             console.error('111error: ', err);
//           });
// 
//           db1.add('store1', true, 'delivered113');
//           db1.add('store7', true, 'deell77888');
//           db1.close();
//         },

        
        // demo4: auto generate keys: (//****************  bei db2/store4 wird der Key automatisch eingefügt,- vermeidet Fehler */))
        // async  demo4() {
        //   const db2 = await openDB('db2', 1);
        //   // db2.add('store3', { id: 'cat001', strength: 10, speed: 10 });
        //   // db2.add('store3', { id: 'cat002', strength: 11, speed: 9 });
        //   db2.add('store4', { id: 'cat003', strength: 8, speed: 12 });
        //   db2.add('store4', { id: 'cat004', strength: 12, speed: 13 });
        //   db2.close();
        // },

        async demo5() {
          const db2 = await openDB('db2', 1);
          
          db2.get('store3', 'cat001').then(console.log);// retrieve by key:
         
          db2.getAll('store3').then(console.log); // retrieve all:
          
          db2.count('store3').then(console.log);// count the total number of items in a store:
          
          db2.getAllKeys('store3').then(console.log);// get all keys:
          db2.close();
        },


       // !!!!!!!   Use db.put() instead of db.add() if you want to update / overwrite an existing value. If the value didn't exist before, it'd be the same as add().
        // PUT has the same meaning as ADD  in IndexedDB, so you can run demo6 as many times as you want. 
        // If you used add() instead of put(), error would occur because you're trying to add a new item with an existing key, and keys must be unique.
        async  demo6() {
          
          // replace cat001 with a supercat:
          const db2 = await openDB('db2', 1);
          db2.put('store4', { id: 'cat001', strength: 775555, speed: 55555 });
          db2.put('store4', { id: 'cat004', strength: 772222, speed: 2222 });
          db2.close();
        }


}



//import axios from 'axios';


//import serveradresse_settings from '@/settings/serveradresse_settings.js';
//const url = serveradresse_settings.config_server_adresse.url;

// const url = 'http://localhost:3100/api/';
//const url = 'https://api.technik-seenotretter.de/api/';

// const indexedDB =
//    window.indexedDB ||
//    window.mozIndexedDB ||
//    window.webkitIndexedDB ||
//    window.msIndexedDB ||
//    window.shimIndexedDB;
// 
// if (!indexedDB) {
//    document.write("IndexedDB could not be found in this browser.");
// }
// //const request = indexedDB.open("MyDatabase", 1);
// 
// 
// 
// export default {
//  
//   
// return 
// 
// };