import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import router from './router';
import store from './store';
//import Axios from 'axios';
//import {AxiosInstance as Axios} from "axios";

import Axios from 'axios';

import './registerServiceWorker'



Vue.config.productionTip = false

export const EventBus = new Vue();

// set auth header
Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;


new Vue({
  vuetify,
  router,
  store, 
  // Axios,
  render: h => h(App)
}).$mount('#app')



Vue.use(vuetify, {
  breakpoint: {
    thresholds: {
      md: 800
    },
    scrollbarWidth: 100
  }
})