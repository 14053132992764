// src/services/AuthService.js

import axios from 'axios';


import serveradresse_settings from '@/settings/serveradresse_settings.js';
const url = serveradresse_settings.config_server_adresse.url;


// const url = 'http://localhost:3100/api/';
//const url = 'https://api.technik-seenotretter.de/api/';


export default {


  async neue_info_einfuegen(credentials) {
    return axios
      .post(url + 'neue_info_einfuegen/', credentials)
      .then(response => response.data);
  },

 async zeigeinfo_per_id(id_sar) {
    return axios
      .post(url + 'zeigeaufgabe_per_id/', id_sar)
      .then(response => response.data);
  },
 
//   async zeigeinfos(credentials) {
//     return axios
//       .post(url + 'zeigeaufgaben/', credentials)
//       .then(response => response.data);
//   },

  async zeigeinfo_per_id_zuordnung_final_emv_sar(credentials) {
    return axios
      .post(url + 'zeigeinfo_per_id_zuordnung_final_emv_sar/', credentials)
      .then(response => response.data);
  },

  async zeigeinfo_per_id_zuordnung_final_gmv_sar(credentials) {
    return axios
      .post(url + 'zeigeinfo_per_id_zuordnung_final_gmv_sar/', credentials)
      .then(response => response.data);
  },

  // async zeige_statusauswahl_liste() {
  //   return axios
  //     .post(url + 'zeige_statusauswahl_liste/')
  //     .then(response => response.data);
  // },
  

  async update_info(credentials) {
    return axios
      .post(url + 'update_info/', credentials)
      .then(response => response.data);
  },

  async update_text_feld(credentials) {
    return axios
      .post(url + 'update_text_feld/', credentials)
      .then(response => response.data);
  },

};